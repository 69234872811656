export default function EventInfo({ name, date, venue }) {
    return (
        <div className="w-full flex flex-col xl:flex-row items-center justify-around gap-2 xl:gap-6 mt-5 md:mt-0">
            <h1 className="text-sm xl:text-xl font-bold text-[#3C3C3C] dark:text-white !select-text">
                Date:<span className="!select-text dark:text-[#d1d5db] font-light ml-1">{date}</span>
            </h1>
            <h1 className="flex text-sm xl:text-xl font-bold text-[#3C3C3C] dark:text-white !select-text">
                Name:<span className="!select-text dark:text-[#d1d5db] font-light ml-1">{name}</span>
            </h1>
            <h1 className="text-sm xl:text-xl font-bold text-[#3C3C3C] dark:text-white !select-text">
                Venue:<span className="!select-text dark:text-[#d1d5db] font-light ml-1">{venue}</span>
            </h1>
        </div>
    );
}