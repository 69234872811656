import React, { useMemo, useEffect, useRef, useState } from "react";

const AxsMapDisplay = ({ axsMapInfo, selectedSections, setSelectedSections }) => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const [viewBox, setViewBox] = useState("0 0 1000 1000");
  const [isShiftSelecting, setIsShiftSelecting] = useState(false);

  const handleMouseDown = (event) => {
    if (event.shiftKey) {
      setIsShiftSelecting(true);
      handleSelection(event);
    }
  };

  const handleMouseUp = () => {
    setIsShiftSelecting(false);
  };

  const handleMouseMove = (event) => {
    if (isShiftSelecting) {
      handleSelection(event);
    }
  };

  const handleSelection = (event) => {
    const target = event.target.closest("[data-fl]");
    if (target) {
      const sectionName = target.getAttribute("data-fl");
      setSelectedSections(selectedSections.includes(sectionName) ? selectedSections : [...selectedSections, sectionName]);
    }
  };

  const handleClick = (event) => {
    if (!event.shiftKey) {
      const target = event.target.closest("[data-fl]");
      if (target) {
        const sectionName = target.getAttribute("data-fl");
        setSelectedSections(selectedSections.includes(sectionName)? selectedSections.filter((name) => name !== sectionName) : [...selectedSections, sectionName]);
      }
    }
  };

  const getFontSize = (item) => {
    return item.fs || 14;
  };

  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const svgContent = useMemo(() => {
    const { svgItems, baseImage } = axsMapInfo;
    if (!baseImage) return null;

    let svgElement;
    if (typeof baseImage === "string" && baseImage.startsWith("http")) {
      svgElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      svgElement.setAttribute("viewBox", viewBox);
      svgElement.setAttribute("width", "1000");
      svgElement.setAttribute("height", "1000");
      const image = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      image.setAttribute("href", baseImage);
      image.setAttribute("width", "100%");
      image.setAttribute("height", "100%");
      image.setAttribute("preserveAspectRatio", "xMidYMid slice");
      svgElement.appendChild(image);
    } else if (isBase64(baseImage)) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(atob(baseImage), "image/svg+xml");
      svgElement = svgDoc.documentElement;

      const svgViewBox = svgElement.getAttribute("viewBox");
      if (svgViewBox) {
        setViewBox(svgViewBox);
      } else {
        svgElement.setAttribute("viewBox", viewBox);
      }

      svgElement.setAttribute("width", "100%");
      svgElement.setAttribute("height", "100%");
      svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
    } else {
      console.error("Unsupported baseImage format");
      return null;
    }

    const interactiveGroup = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );
    interactiveGroup.setAttribute("id", "interactive-elements");

    svgItems.forEach((item) => {
      const groupElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      const sectionName = item.fl || item.id.replace("S_", "");
      const isSelected = selectedSections.includes(sectionName);

      pathElement.setAttribute("d", item.pt);
      pathElement.setAttribute(
        "fill",
        isSelected ? "rgba(2, 108, 223, 0.7)" : "rgba(2, 108, 223, 0.3)"
      );
      pathElement.setAttribute("stroke", isSelected ? "#b81ee3" : "#026cdf");
      pathElement.setAttribute("stroke-width", "2");
      pathElement.setAttribute("id", item.id);
      pathElement.setAttribute("data-fl", sectionName);
      pathElement.style.cursor = "pointer";

      groupElement.appendChild(pathElement);

      if (sectionName && item.a) {
        const textElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textElement.setAttribute("x", item.a[0]);
        textElement.setAttribute("y", item.a[1]);
        textElement.setAttribute("fill", "black");
        textElement.setAttribute("font-size", getFontSize(item));
        textElement.setAttribute("text-anchor", "middle");
        textElement.setAttribute("dominant-baseline", "middle");
        textElement.setAttribute("pointer-events", "none");
        textElement.setAttribute("user-select", "none");
        textElement.setAttribute(
          "style",
          "-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;"
        );
        textElement.textContent = sectionName;

        groupElement.appendChild(textElement);
      }

      groupElement.setAttribute("data-fl", sectionName);
      interactiveGroup.appendChild(groupElement);
    });

    svgElement.appendChild(interactiveGroup);

    return svgElement.outerHTML;
  }, [axsMapInfo, selectedSections, viewBox]);

  useEffect(() => {
    if (containerRef.current && svgRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        // Dynamically adjust based on container size if necessary
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [svgContent]);

  useEffect(() => {
    if (svgRef.current && svgContent) {
      svgRef.current.innerHTML = svgContent;
    }
  }, [svgContent]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <svg
        ref={svgRef}
        viewBox={viewBox}
        style={{
          maxWidth: "100%",
          width: "100%",
          maxHeight: "100%",
          display: "block",
        }}
        preserveAspectRatio="xMidYMid meet"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        onMouseLeave={handleMouseUp}
      />
    </div>
  );
};

export default AxsMapDisplay;
