import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import FilterListIcon from "@material-ui/icons/FilterList";
import BlockIcon from "@material-ui/icons/Block";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    color: "#fff",
    backgroundColor: "#454545",
    borderRadius: "10px",
    margin: "40px 0px",
  },
  tableCell: {
    verticalAlign: "middle",
    color: "#fff",
    textAlign: "center",
    border: "1px solid #222222",
    padding: "16px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  eventContainer: {
    display: "flex",
    alignItems: "center",
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  addButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: "15px",
    width: "auto",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  toggleButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  filterButton: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
  disableButton: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    borderRadius: "15px",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

function UrlAnalytics({ url, mainUser }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [urlLogs, setUrlLogs] = useState({});

  const getEventStyle = (eventType) => {
    switch (eventType) {
      case "URL Removal":
        return classes.removeButton;
      case "URL Addition":
        return classes.addButton;
      case "Early Monitor Toggle":
        return classes.toggleButton;
      case "URL Filtered":
        return classes.filterButton;
      case "URL Disable":
        return classes.disableButton;
      default:
        return "";
    }
  };

  const fetchUrlLogs = async () => {
    const url2 = new URL(url);
    let event_id = url2.pathname.split("/").pop();
    if (url.includes("axs.com")) {
      const match = url.match(/e=(\d+)/);
      if (match) {
        event_id = match[1];
      }
    }

    const docId = `${mainUser}-${event_id.toString()}`;
    try {
      const logDoc = doc(db, "UrlLogs", docId);
      const logSnap = await getDoc(logDoc);
      if (logSnap.exists()) {
        const data = logSnap.data();

        setUrlLogs({
          urlRemoveLogs: data.urlRemovals || {},
          urlAddLogs: data.urlAdditions || {},
          earlyMonitorToggles: data.earlyMonitorToggles || {},
          urlFilters: data.urlFilters || {},
          urlDisableLogs: data.urlDisables || {},
        });
      }
    } catch (error) {
      console.error("Error fetching URL logs:", error);
    }
  };

  const handleOpen = async () => {
    setOpen(true);
    await fetchUrlLogs();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getEventIcon = (eventType) => {
    switch (eventType) {
      case "URL Removal":
        return <DeleteIcon className={classes.icon} />;
      case "URL Addition":
        return <AddIcon className={classes.icon} />;
      case "Early Monitor Toggle":
        return <ToggleOnIcon className={classes.icon} />;
      case "URL Filtered":
        return <FilterListIcon className={classes.icon} />;
      case "URL Disable":
        return <BlockIcon className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#670004",
          borderRadius: "9px",
          cursor: "pointer",
          padding: "5px 20px",
          color: "#fff",
          fontSize: "12px",
          fontFamily: "'Inter', sans-serif",
        }}
        onClick={handleOpen}
      >
        Event Logs
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="url-analytics-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <div
          style={{
            backgroundColor: "#222222",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#2c2c2c",
              borderRadius: "40px",
              padding: "5px 10px",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  margin: 0,
                  flex: 1,
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Event Logs
              </p>
            </div>
            <button
              style={{
                backgroundColor: "#595959",
                border: "none",
                cursor: "pointer",
                borderRadius: "50%",
                width: "18px",
                height: "18px",
                padding: "4px",
                marginLeft: "5px",
                marginRight: "5px",
                position: "absolute",
                right: "5px",
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 14 14"
              >
                <path d="M3.854 3.146a.5.5 0 0 1 0 .708L8.293 8l-4.44 4.44a.5.5 0 1 1-.708-.708L7.586 8 3.146 3.854a.5.5 0 0 1 0-.708zM8 7.586l4.44-4.44a.5.5 0 1 1 .708.708L8.707 8l4.44 4.44a.5.5 0 1 1-.708.708L8 8.707l-4.44 4.44a.5.5 0 1 1-.708-.708L7.293 8 2.854 3.562a.5.5 0 0 1 .708-.708L8 7.586z" />
              </svg>
            </button>
          </div>
          <DialogContent>
            <Table className={classes.table}>
              <TableHead>
                <TableRow style={{ height: "10px" }}>
                  <TableCell
                    style={{
                      color: "#fff",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      backgroundColor: "#2d2d2d",
                      textAlign: "center",
                      border: "1px solid #222222",
                    }}
                  >
                    Timestamp
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      backgroundColor: "#2d2d2d",
                      textAlign: "center",
                      border: "1px solid #222222",
                    }}
                  >
                    Event
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#fff",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      backgroundColor: "#2d2d2d",
                      textAlign: "center",
                      border: "1px solid #222222",
                    }}
                  >
                    User
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  ...Object.entries(urlLogs.urlRemoveLogs || {}),
                  ...Object.entries(urlLogs.urlAddLogs || {}),
                  ...Object.entries(urlLogs.earlyMonitorToggles || {}),
                  ...Object.entries(urlLogs.urlFilters || {}),
                  ...Object.entries(urlLogs.urlDisableLogs || {}),
                ]
                  .sort(([timestampA], [timestampB]) =>
                    timestampB.localeCompare(timestampA)
                  )
                  .map(([timestamp, data]) => {
                    let eventType = "";
                    let eventMatch = false;
                    let eventDetails = "";

                    if (urlLogs.urlRemoveLogs[timestamp]) {
                      eventType = "URL Removal";
                      eventMatch = data.e === url;
                    } else if (urlLogs.urlAddLogs[timestamp]) {
                      eventType = "URL Addition";
                      eventMatch =
                        Array.isArray(data.e) && data.e.includes(url);
                    } else if (urlLogs.earlyMonitorToggles[timestamp]) {
                      eventType = "Early Monitor Toggle";
                      eventMatch = data.e === url;
                      eventDetails = data.c ? "Enabled" : "Disabled";
                    } else if (urlLogs.urlFilters[timestamp]) {
                      eventType = "URL Filtered";
                      eventMatch = data.e && url.includes(data.e);
                    } else if (urlLogs.urlDisableLogs[timestamp]) {
                      eventType = "URL Disable";
                      eventMatch = data.event_id && url.includes(data.event_id);
                    }

                    if (eventMatch) {
                      return (
                        <TableRow key={timestamp}>
                          <TableCell
                            className={classes.tableCell}
                            style={{ padding: "16px" }}
                          >
                            {new Date(timestamp).toLocaleString()}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ padding: "16px" }}
                          >
                            <div
                              className={`${
                                classes.eventContainer
                              } ${getEventStyle(eventType)}`}
                            >
                              {getEventIcon(eventType)}
                              <span>{eventType}</span>
                              {eventDetails && (
                                <span style={{ marginLeft: "8px" }}>
                                  ({eventDetails})
                                </span>
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            style={{ padding: "16px" }}
                          >
                            {data.u}
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
              </TableBody>
            </Table>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default UrlAnalytics;
