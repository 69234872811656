import React, { useEffect, useState } from "react";
import Loading from "./loading";
import { organizeSections } from "./mapUtilities";
import AxsMapDisplay from "./axsMap";
import { TransformComponent } from "react-zoom-pan-pinch";

const MapDisplay = ({
  paths,
  info,
  mapid,
  handleSvgPathClick,
  selectedPaths,
  SvgPaths,
  SvgLabelList,
  axs,
  axsMapInfo,
  setSelectedSections,
  isFilterAvailable,
  isMlb,
  isSeatGeek,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [organized, setOrganized] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 500);

    let organized = organizeSections(paths);
    if (axsMapInfo) {
      const { svgItems } = axsMapInfo;

      let sections = [];

      for (let i = 0; i < svgItems.length; i++) {
        const section = svgItems[i];
        const sectionName = section.fl;

        if (sectionName) {
          sections.push({
            name: sectionName,
            id: section.id,
          });
        }
      }

      organized = organizeSections(sections);
    }
    setOrganized(organized);

    return () => clearTimeout(timer);
  }, [paths, axsMapInfo]);

  const handleGroupClick = (group) => {
    const sectionIds = organized[group].map((sec) => ({
      name: sec.name,
      id: sec.id,
    }));
    handleSvgPathClick(sectionIds);
  };

  const MapStyle = {
    backgroundImage: `url('https://mapsapi.tmol.io/maps/geometry/image/${mapid}?removeFilters=ISM_Shadow&amp;avertaFonts=true&amp;app=PRD2663_EDP_NA')`,
    transform: "rotate(0deg)",
  };

  const isPaths = (paths.length > 0 && info.length > 0 && mapid);
  const isAXS = (axsMapInfo);

  return (
    <div className="w-full">
      {(!isPaths && !isAXS) && (
        <Loading
          isLoading={isLoading}
          axs={axs}
          isMlb={isMlb}
          isSeatGeek={isSeatGeek}
        />
      )}
      <div className={`page__wrapper bg-[#CACACA] dark:bg-[rgb(57,57,57)] ${(!isPaths && !isAXS) ? "hidden" : ""}`}>
        <TransformComponent>
          {isPaths && (
            <div className="seatmap-container seatmap-container--sim">
              <div className="map__wrapper">
                <div className="map rounded-xl">
                  <svg data-component="svg" className="map__svg" viewBox="0 0 10240 7680" style={MapStyle}>
                    <g>
                      <SvgPaths onPathClick={handleSvgPathClick} clickedPaths={selectedPaths} data={paths} />
                    </g>
                    <g>
                      <SvgLabelList jsonData={info} />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          )}
          {isAXS && (
            <AxsMapDisplay
              axsMapInfo={axsMapInfo}
              selectedSections={selectedPaths}
              setSelectedSections={setSelectedSections}
            />
          )}
        </TransformComponent>
      </div>
      {organized && Object.keys(organized).length > 0 && (
        <div className="flex flex-col mt-6">
          <h6 className="mb-2 text-lg font-semibold text-[#3C3C3C] dark:text-white">Section Types</h6>
          <div className="flex flex-wrap gap-3">
            {Object.keys(organized).map((group) => (
              <button
                key={group}
                disabled={!isFilterAvailable}
                className="w-full px-3 py-1.5 max-w-max rounded-full bg-[rgba(64,84,175)]"
                variant="contained"
                onClick={() => handleGroupClick(group)}
              >
                {group}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapDisplay;
