import { useMemo } from "react";
import { parse_places } from "./parse_places";

export const BuildSeats = (logs, map, changes, selectedTimeStamp) => {
  return useMemo(() => {
    if (!logs || !map) {
      return [];
    }

    const newSeats = [];

    for (const [priceName, places] of Object.entries(logs)) {
      let [price, name] = priceName.split("-");

      if (name === "R") {
        name = "Verified Resale";
      }

      if (typeof places === "number") {
        continue;
      } else {
        for (const place of places) {
          const listPlaces = parse_places(place);
          // find seats with the place name
          for (let i = 0; i < listPlaces.length; i++) {
            const placeName = listPlaces[i];
            const seat = map[placeName];
            if (seat) {
              const newSeat = {
                ...seat,
                3: price,
                4: name,
                5: priceName,
              };

              newSeats.push(newSeat);
            }
          }
        }
      }
    }

    if (selectedTimeStamp !== "Old" && selectedTimeStamp !== null && changes) {
      const timeStamps = Object.keys(changes);
      // find all the changes leading up to the selected time

      let selectedChanges = [];

      if (selectedTimeStamp === "all") {
        selectedChanges = Object.values(changes);
      } else {
        selectedChanges = timeStamps
          .filter((time) => new Date(time) <= new Date(selectedTimeStamp))
          .map((time) => changes[time]);
      }

      for (let i = 0; i < selectedChanges.length; i++) {
        const change = selectedChanges[i];
        for (let j = 0; j < change.length; j++) {
          const { key, type, value } = change[j];
          let [price, name] = key.split("-");

          if (name === "R") {
            name = "Verified Resale";
          }

          const changeSeats = [];
          // build out places array
          for (let j = 0; j < value.length; j++) {
            const place = value[j];
            const listPlaces = parse_places(place);
            for (let k = 0; k < listPlaces.length; k++) {
              const placeName = listPlaces[k];
              const seat = map[placeName];
              if (seat) {
                const newSeat = {
                  ...seat,
                  3: price,
                  4: name,
                  5: key,
                };

                changeSeats.push(newSeat);
              }
            }
          }

          // type is either add or remove
          if (type === "add") {
            newSeats.push(...changeSeats);
          } else if (type === "remove") {
            for (let j = 0; j < changeSeats.length; j++) {
              const changeSeat = changeSeats[j];
              const index = newSeats.findIndex(
                (seat) =>
                  seat[0] === changeSeat[0] &&
                  seat[1] === changeSeat[1] &&
                  seat[2] === changeSeat[2] &&
                  seat[3] === changeSeat[3] &&
                  seat[4] === changeSeat[4] &&
                  seat[5] === changeSeat[5]
              );

              // log what is being removed

              newSeats.splice(index, 1);
            }
          } else {
            console.error("Invalid change type");
          }
        }
      }
    }

    return newSeats;
  }, [logs, map, changes, selectedTimeStamp]);
};
