export const parse_places = (string) => {
  let prefix_stack = [""];
  let result = [];
  let current_word = [];

  function word() {
    return prefix_stack.slice(-1)[0] + current_word.join("");
  }

  function add_word() {
    if (current_word.length > 0) {
      result.push(word());
      current_word = [];
    }
  }

  function push_prefix() {
    prefix_stack.push(word());
    current_word = [];
  }

  function pop_prefix() {
    prefix_stack.pop();
  }

  if (string === undefined) {
    return result;
  }

  for (let i = 0; i < string.length; i++) {
    let c = string.charAt(i);
    if (c === "[") {
      push_prefix();
    } else if (c === "]") {
      add_word();
      pop_prefix();
    } else if (c === ",") {
      add_word();
    } else {
      current_word.push(c);
    }
  }

  add_word();
  return result;
};
