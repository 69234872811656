import React from "react";
import { ListItem, ListItemText, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../../ThemeContext";

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
  },
  listItemLight: {
    cursor: "pointer",
    position: "relative",
    display: "flex",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  earlyBackground: {
    backgroundImage: "linear-gradient(to left, #FFAA00, #FFDE9C)",
    "&:hover": {
      backgroundImage: "linear-gradient(to left, #ffeb3b, #ffeb8e)",
    },
  },
  earlyBeta: {
    backgroundColor: "#ff80ab",
    "&:hover": {
      backgroundColor: "#ff4081",
    },
  },
  axs: {
    backgroundImage: "linear-gradient(to left, #0052CC, #7BA9FF)",
    "&:hover": {
      backgroundImage: "linear-gradient(to left, #003D99, #4B89FF)",
    },
  },
  eventButton: {
    textTransform: "none",
    height: "100%",
    minWidth: "80px",
    marginLeft: theme.spacing(1.5),
    backgroundColor: "#4caf50",
    margin: 0,
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  secondaryTextDarkMode: {
    color: "rgba(255, 255, 255, 0.7)",
  },
  earlyDarkMode: {
    color: "black",
  },
  lowStockBackground: {
    backgroundImage: "linear-gradient(to left, #b85ec8, #e9ceea)",
  },
  mlbBackground: {
    backgroundImage: "linear-gradient(to left, #0044cc, #7aa3e6)",
  },
  qPing: {
    backgroundImage: "linear-gradient(to right, #d7aefb, #8e44ad)",
  },
  qPingDarkMode: {
    color: "black",
    backgroundImage: "linear-gradient(to right, #d7aefb, #8e44ad)",
  },
  seatgeekBackground: {
    backgroundImage: "linear-gradient(to left, #FF5B49, #ff6e5e)",
  },
  listItemBorder: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  listItemBorderDarkMode: {
    "&:hover": {
      backgroundColor: "#333333", // This is a dark grey color
    },
  },
}));

const EmailItem = ({
  email = {},
  handleClick,
  event_info = {},
  updateUserData,
  qEvent,
}) => {
  const classes = useStyles();

  let {
    opened,
    eventUrl,
    timestamp = Date.now(),
    quantity = 0,
    priceDrop,
    qPing,
    lowStock,
  } = email;

  const { darkMode } = useTheme();

  const {
    name = "Unknown Name",
    venue = "Unknown Venue",
    date = "Unknown Date",
  } = event_info;

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/uri-list", eventUrl);
    event.dataTransfer.setData("text/plain", eventUrl);
  };

  const handleDragEnd = (event) => {
    const droppedTarget = document.elementFromPoint(
      event.clientX,
      event.clientY
    );
    if (droppedTarget) {
      window.open(eventUrl, "_blank");
    }
  };

  return (
    <React.Fragment>
      <ListItem
        button
        className={`${darkMode ? classes.listItem : classes.listItemLight}`}
        onClick={() => {
          if (!qPing) {
            handleClick(email);
          } else {
            window.open(eventUrl, "_blank");
          }
        }}
        disableRipple
        style={{ backgroundColor: darkMode ? "#121212" : "white" }}
      >
        {!opened && !qPing && priceDrop === undefined ? (
          <div
            style={{
              backgroundColor: darkMode
                ? email.early
                  ? "#950006"
                  : "#ffc001"
                : "Blue",
              borderRadius: "50%",
              width: "11px",
              height: "11px",
              marginRight: "10px",
              alignSelf: "center",
              position: "absolute",
              top: 20,
              right: "145px",
            }}
          ></div>
        ) : null}
        {priceDrop === true ? (
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "10px",
              padding: "5px 10px",
              alignSelf: "center",
              position: "absolute",
              bottom: "15px",
              right: "149px",
              color: "green",
              transition: "all 0.5s ease-in-out",
              borderTop: "2px solid green",
              borderLeft: "2px solid green",
              boxShadow: "0px 0px 10px rgba(255, 165, 0, 0.5)",
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            price drop
          </div>
        ) : null}
        {priceDrop === false ? (
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "10px",
              padding: "5px 10px",
              alignSelf: "center",
              position: "absolute",
              bottom: "15px",
              right: "149px",
              color: "orange",
              transition: "all 0.5s ease-in-out",
              borderTop: "2px solid orange",
              borderLeft: "2px solid orange",
              boxShadow: "0px 0px 10px rgba(255, 165, 0, 0.5)",
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            price increase
          </div>
        ) : null}

        <ListItemText
          className={`${
            qPing
              ? darkMode
                ? classes.qPingDarkMode
                : classes.qPing
              : email.earlyBeta
              ? classes.earlyBeta
              : email.early
              ? classes.earlyBackground
              : email.axs
              ? classes.axs
              : lowStock
              ? classes.lowStockBackground
              : email.mlb
              ? classes.mlbBackground
              : email.seatgeek
              ? classes.seatgeekBackground
              : darkMode
              ? classes.listItemBorderDarkMode
              : classes.listItemBorder
          }`}
          primary={
            qPing ? (
              <>
                {`${name} - ${venue}, ${date} `}

                <br />
                <img
                  src="/Queue-logo.png"
                  alt="Q-Tickets"
                  style={{
                    height: "25px",
                    width: "auto",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                />
                <strong>QUEUE OPENED</strong>
                <img
                  src="/Queue-logo.png"
                  alt="Q-Tickets"
                  style={{
                    height: "25px",
                    width: "auto",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    display: "inline-block",
                  }}
                />
              </>
            ) : qEvent ? (
              <>
                {`${name} - ${venue}, ${date} `}
                <strong>({quantity} tickets found)</strong>
                <img
                  src="/Queue-logo.png"
                  alt="Q-Tickets"
                  style={{
                    height: "25px",
                    width: "auto",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    filter: darkMode && !email.early ? "invert(1)" : "",
                    display: "inline-block",
                  }}
                />
              </>
            ) : (
              <span style={{ fontFamily: "'Inter', sans-serif" }}>
                {`${name} - ${venue}, ${date} `}
                <strong>
                  {`(`}
                  {quantity} {` tickets found)`}
                </strong>
              </span>
            )
          }
          secondary={
            <span
              style={{
                fontFamily: "'Inter', sans-serif",
                color: qPing ? "black" : "",
              }}
            >{`Release Time: ${new Date(timestamp).toLocaleString()}`}</span>
          }
          classes={{
            secondary: `${darkMode ? classes.secondaryTextDarkMode : ""} ${
              email.early
                ? classes.earlyDarkMode
                : email.axs
                ? classes.earlyDarkMode
                : ""
            }`,
            primary:
              email.early && darkMode
                ? classes.earlyDarkMode
                : email.axs && darkMode
                ? classes.earlyDarkMode
                : "",
          }}
          style={{
            border: "1px solid #7E7E7E",
            padding: "5px 30px 5px 10px",
            poition: "relative",
            borderRadius: "8px",
            width: "75%",
            minHeight: "85px",
          }}
        />
        {eventUrl && (
          <a
            href={eventUrl}
            target="_blank"
            rel="noopener noreferrer"
            draggable="true"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onClick={(event) => {
              updateUserData();
              event.stopPropagation();
            }}
          >
            <Button
              className={classes.eventButton}
              variant="contained"
              style={{
                backgroundColor: "#670004",
                color: "white",
                height: "85px",
                width: "120px",
                borderRadius: "10px",
                fontFamily: "'Inter', sans-serif",
              }}
            >
              Open Event
            </Button>
          </a>
        )}
      </ListItem>
    </React.Fragment>
  );
};

export default EmailItem;
