import React, { useState, useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  filterContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(2),
  },
  priceRangeContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  priceRangeLabel: {
    marginRight: theme.spacing(2),
  },
  nameFilterContainer: {
    marginTop: theme.spacing(2),
  },
  sectionFilterContainer: {
    marginTop: theme.spacing(2),
  },
}));

const CustomizableTable = ({
  data,
  changes,
  onSelectedTimeStampChange,
  selectedTimeStamp,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedSection, setSelectedSection] = useState("all");

  useEffect(() => {
    const prices = data.map((row) => parseFloat(row["3"]));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    setPriceRange([minPrice, maxPrice]);
  }, [data]);

  useEffect(() => {
    const uniqueNames = [...new Set(data.map((row) => row["4"]))];
    setSelectedNames(uniqueNames);
  }, [data]);

  const handleSort = useCallback(
    (column) => {
      if (sortColumn === column) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    },
    [sortColumn, sortDirection]
  );

  const handleSearch = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handlePriceRangeChangeCommitted = useCallback((event, newValue) => {
    setPriceRange(newValue);
  }, []);

  const handleNameChange = useCallback((event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setSelectedNames((prevSelectedNames) =>
      checked
        ? [...prevSelectedNames, name]
        : prevSelectedNames.filter((n) => n !== name)
    );
  }, []);

  const handleSectionChange = useCallback((event) => {
    setSelectedSection(event.target.value);
  }, []);

  const handleTimeStampChange = useCallback(
    (event) => {
      onSelectedTimeStampChange(event.target.value);
    },
    [onSelectedTimeStampChange]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const uniqueNames = useMemo(
    () => [...new Set(data.map((row) => row["4"]))],
    [data]
  );
  const uniqueSections = useMemo(
    () => [...new Set(data.map((row) => row["0"]))],
    [data]
  );

  const filteredData = useMemo(() => {
    return data.filter((row) => {
      const isSearchMatch =
        row["0"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        row["1"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        row["2"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        row["3"].toLowerCase().includes(searchQuery.toLowerCase()) ||
        row["4"].toLowerCase().includes(searchQuery.toLowerCase());
      const isPriceInRange =
        parseFloat(row["3"]) >= priceRange[0] &&
        parseFloat(row["3"]) <= priceRange[1];
      const isNameSelected = selectedNames.includes(row["4"]);
      const isSectionSelected =
        selectedSection === "all" || selectedSection === row["0"];
      return (
        isSearchMatch && isPriceInRange && isNameSelected && isSectionSelected
      );
    });
  }, [data, searchQuery, priceRange, selectedNames, selectedSection]);

  const sortedData = useMemo(() => {
    return sortColumn
      ? [...filteredData].sort((a, b) => {
          const aValue = a[sortColumn];
          const bValue = b[sortColumn];
          return sortDirection === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        })
      : filteredData;
  }, [filteredData, sortColumn, sortDirection]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, page, rowsPerPage]);

  return (
    <div>
      <Paper className={classes.filterContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.priceRangeContainer}>
              <Typography className={classes.priceRangeLabel}>
                Price Range:
              </Typography>
              <Slider
                value={priceRange}
                onChangeCommitted={handlePriceRangeChangeCommitted}
                valueLabelDisplay="auto"
                min={Math.min(...data.map((row) => parseFloat(row["3"])))}
                max={Math.max(...data.map((row) => parseFloat(row["3"])))}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.nameFilterContainer}>
              {uniqueNames.map((name) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={selectedNames.includes(name)}
                      onChange={handleNameChange}
                      name={name}
                    />
                  }
                  label={name}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.sectionFilterContainer}>
              <Select
                value={selectedSection}
                onChange={handleSectionChange}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="all">All Sections</MenuItem>
                {uniqueSections.map((section) => (
                  <MenuItem key={section} value={section}>
                    {section}
                  </MenuItem>
                ))}
              </Select>
              <Select
                onChange={handleTimeStampChange}
                value={selectedTimeStamp}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="Old">Initial Seats</MenuItem>
                <MenuItem value="all">Recent Changes</MenuItem>
                {changes
                  ? Object.keys(changes)
                      .sort((a, b) => new Date(a) - new Date(b))
                      .map((time) => (
                        <MenuItem key={time} value={time}>
                          {new Date(time).toLocaleString()}
                        </MenuItem>
                      ))
                  : null}
              </Select>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customizable table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("0")}
              >
                Section
                {sortColumn === "0" && (sortDirection === "asc" ? " ⬆" : " ⬇")}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("1")}
              >
                Row
                {sortColumn === "1" && (sortDirection === "asc" ? " ⬆" : " ⬇")}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("2")}
              >
                Seat Number
                {sortColumn === "2" && (sortDirection === "asc" ? " ⬆" : " ⬇")}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("3")}
              >
                Price
                {sortColumn === "3" && (sortDirection === "asc" ? " ⬆" : " ⬇")}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                onClick={() => handleSort("4")}
              >
                Name
                {sortColumn === "4" && (sortDirection === "asc" ? " ⬆" : " ⬇")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow key={`${row["0"]}-${row["1"]}-${row["2"]}-${row["3"]}`}>
                <TableCell>{row["0"]}</TableCell>
                <TableCell>{row["1"]}</TableCell>
                <TableCell>{row["2"]}</TableCell>
                <TableCell>{row["3"]}</TableCell>
                <TableCell>{row["4"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      </TableContainer>
    </div>
  );
};

export default CustomizableTable;
