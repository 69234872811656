import React, { useEffect, useState } from "react";
import Loading from "./loading";
import { organizeSections } from "./mapUtilities";
import { Button } from "@mui/material";
import AxsMapDisplay from "./axsMap";

const MapDisplay = ({
  paths,
  info,
  mapid,
  darkMode,
  handleSvgPathClick,
  selectedPaths,
  SvgPaths,
  SvgLabelList,
  axs,
  axsMapInfo,
  setSelectedSections,
  isMlb,
  isSeatGeek,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [organized, setOrganized] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
    }, 500);

    let organized = organizeSections(paths);
    if (axsMapInfo) {
      const { svgItems } = axsMapInfo;

      let sections = [];

      for (let i = 0; i < svgItems.length; i++) {
        const section = svgItems[i];
        const sectionName = section.fl;

        if (sectionName) {
          sections.push({
            name: sectionName,
            id: section.id,
          });
        }
      }

      organized = organizeSections(sections);
    }
    setOrganized(organized);

    return () => clearTimeout(timer);
  }, [paths, axsMapInfo]);

  const handleGroupClick = (group) => {
    const sectionIds = organized[group].map((sec) => ({
      name: sec.name,
      id: sec.id,
    }));
    handleSvgPathClick(sectionIds);
  };

  const MapStyle = {
    backgroundImage: `url('https://mapsapi.tmol.io/maps/geometry/image/${mapid}?removeFilters=ISM_Shadow&amp;avertaFonts=true&amp;app=PRD2663_EDP_NA')`,
    transform: "rotate(0deg)",
  };

  return paths.length > 0 && info.length > 0 && mapid ? (
    <div className="page__wrapper">
      <div className="seatmap-container seatmap-container--sim">
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            left: "10px",
            top: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: "10px",
            borderRadius: "5px",
            height: "calc(100% - 20px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {organized &&
            Object.keys(organized).map((group) => (
              <Button
                style={{
                  marginBottom: "5px",
                }}
                key={group}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleGroupClick(group)}
              >
                {group}
              </Button>
            ))}
        </div>
        <div className="map__wrapper">
          <div
            className="map"
            style={{
              backgroundColor: darkMode ? "#383838" : "#fff",
            }}
          >
            <svg
              data-component="svg"
              className="map__svg"
              viewBox="0 0 10240 7680"
              style={MapStyle}
            >
              <g>
                <SvgPaths
                  onPathClick={handleSvgPathClick}
                  clickedPaths={selectedPaths}
                  data={paths}
                />
              </g>
              <g>
                <SvgLabelList jsonData={info} />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  ) : axsMapInfo ? (
    <div
      className="page__wrapper"
      style={{
        backgroundColor: darkMode ? "#383838" : "#fff",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          left: "10px",
          top: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: "10px",
          borderRadius: "5px",
          height: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {organized &&
          Object.keys(organized).map((group) => (
            <Button
              style={{
                marginBottom: "5px",
              }}
              key={group}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleGroupClick(group)}
            >
              {group}
            </Button>
          ))}
      </div>
      <AxsMapDisplay
        axsMapInfo={axsMapInfo}
        selectedSections={selectedPaths}
        setSelectedSections={setSelectedSections}
      />
    </div>
  ) : (
    <Loading
      isLoading={isLoading}
      axs={axs}
      isMlb={isMlb}
      isSeatGeek={isSeatGeek}
    />
  );
};

export default MapDisplay;
