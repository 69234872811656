import { useMemo } from "react";
import moment from "moment";

export const useSortedUrls = (urls, eventsInfo, sortConfig, early) => {
  return useMemo(() => {
    let sortableUrls = [...urls];
    if (sortConfig !== null) {
      sortableUrls.sort((a, b) => {
        let eventA = eventsInfo[new URL(a).pathname.split("/").pop()] || {};
        let eventB = eventsInfo[new URL(b).pathname.split("/").pop()] || {};

        if (a.includes("mlb.tickets.com") || a.includes("mpv.tickets.com")) {
          let urlObj = new URL(a);
          let searchParams = new URLSearchParams(urlObj.search);
          const eventId = searchParams.get("pid");
          eventA = eventsInfo[eventId] || {};
        }

        if (a.includes("axs.com")) {
          const match = a.match(/e=([0-9]+)/);
          if (match) {
            const eParam = match[1];
            eventA = eventsInfo[eParam] || {};
          } else {
            const parsedUrl = new URL(a);
            const event_id = parsedUrl.pathname.split("/").pop();
            eventA = eventsInfo[event_id] || {};
          }
        }

        if (b.includes("mlb.tickets.com") || b.includes("mpv.tickets.com")) {
          let urlObj = new URL(b);
          let searchParams = new URLSearchParams(urlObj.search);
          const eventId = searchParams.get("pid");
          eventB = eventsInfo[eventId] || {};
        }

        if (b.includes("axs.com")) {
          const match = b.match(/e=([0-9]+)/);
          if (match) {
            const eParam = match[1];
            eventB = eventsInfo[eParam] || {};
          } else {
            const parsedUrl = new URL(b);
            const event_id = parsedUrl.pathname.split("/").pop();
            eventB = eventsInfo[event_id] || {};
          }
        }

        if (sortConfig.key === "eventId") {
          let eventIdA = new URL(a).pathname.split("/").pop();
          let eventIdB = new URL(b).pathname.split("/").pop();

          if (a.includes("mlb.tickets.com") || a.includes("mpv.tickets.com")) {
            let urlObj = new URL(a);
            let searchParams = new URLSearchParams(urlObj.search);
            eventIdA = searchParams.get("pid");
          }

          if (b.includes("mlb.tickets.com") || b.includes("mpv.tickets.com")) {
            let urlObj = new URL(b);
            let searchParams = new URLSearchParams(urlObj.search);
            eventIdB = searchParams.get("pid");
          }

          if (a.includes("axs.com")) {
            const match = a.match(/e=([0-9]+)/);
            if (match) {
              const eParam = match[1];
              eventIdA = eParam;
            }
          }

          if (b.includes("axs.com")) {
            const match = b.match(/e=([0-9]+)/);
            if (match) {
              const eParam = match[1];
              eventIdB = eParam;
            }
          }

          if (eventIdA < eventIdB) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (eventIdA > eventIdB) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else if (sortConfig.key === "date") {
          let dateA = parseDateWithMoment(eventA.date, a);
          let dateB = parseDateWithMoment(eventB.date, b);

          if (!dateA.isValid() && !dateB.isValid()) return 0;
          if (!dateA.isValid()) return 1; // Invalid dates sort to the end
          if (!dateB.isValid()) return -1;

          return sortConfig.direction === "ascending"
            ? dateA.valueOf() - dateB.valueOf()
            : dateB.valueOf() - dateA.valueOf();
        } else {
          if (eventA[sortConfig.key] < eventB[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (eventA[sortConfig.key] > eventB[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    if (sortConfig.key === "early") {
      if (sortConfig.direction === "ascending") {
        sortableUrls = early.concat(sortableUrls);
      } else {
        sortableUrls = sortableUrls.concat(early);
      }
    }
    return sortableUrls;
  }, [urls, eventsInfo, sortConfig, early]);
};

function parseDateWithMoment(dateString, url) {
  if (dateString === "TBD" || dateString === "loading") {
    return moment.invalid();
  }

  let parsedDate;

  if (url.includes("axs.com")) {
    // Parse "Month DD, YYYY - h:mm A" format (e.g., "August 03, 2024 - 8:00 PM")
    parsedDate = moment(dateString, "MMMM DD, YYYY - h:mm A", true);
    if (parsedDate.isValid()) return parsedDate;
  } else {
    // For other URLs, try parsing as ISO 8601
    parsedDate = moment(dateString);
    if (parsedDate.isValid()) return parsedDate;
  }

  // If parsing fails, log a warning and return an invalid date
  console.warn(`Failed to parse date: ${dateString} for URL: ${url}`);
  return moment.invalid();
}

export const useSortConfigUpdate = (currentSortConfig) => {
  return (key) => {
    let direction = "ascending";
    if (
      currentSortConfig &&
      currentSortConfig.key === key &&
      currentSortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    return { key, direction };
  };
};
