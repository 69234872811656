import React, { useState, useMemo } from "react";
import {
  List,
  Dialog,
  DialogContent,
  Button,
  // DialogTitle,
  IconButton,
  // DialogContentText,
  // DialogActions,
} from "@material-ui/core";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import EmailContent from "./components/generateHtml";
// import CloseIcon from "@material-ui/icons/Close";

import Pagination from "./components/pagination";
import EmailItem from "./components/emailItem";

import FilterForm from "../filters/index";

import CircularProgress from "@material-ui/core/CircularProgress";
import updateUserData from "./handlers/saveEmailData";

import { useTableEffects } from "./handlers/Effect";
import { useTableState } from "./handlers/State";
import useStyles from "./handlers/Styles";

import TicketQuantityFilter from "./handlers/TicketQuantityFilter";
import { useEventContext } from "../HomePage/UserDataContext";
import SearchBar from "../HomePage/components/searchBar1";
// import MuteButton from "./components/muteEventDrops";
// import { handleRemoveUrl } from "../HomePage/handlers/urlManipulation";
import NotesModal from "../notes/notes";
import { Link } from "react-router-dom";
import { handleSignOut } from "../HomePage/handlers/user";
import { debounce } from "lodash";
import FilterFormOld from "../filtersOld";

const TicketDrops = () => {
  const classes = useStyles();
  const userContext = useEventContext();
  const {
    eventsInfo,
    user,
    notesDocument,
    early,
    totalEarly,
    // mutedEvents,
    editFilters,
    showTable,
    mainUser,
    eventInfoRef,
    formattedFiltersRef,
    qEvents,
    vividIds,
  } = userContext;

  const filtersDocument = userContext.filters;
  const stubhub = userContext.stubHubInfo;

  const {
    emails,
    setEmails,
    selectedEmail,
    setSelectedEmail,
    searchQuery,
    setSearchQuery,
    currentPage,
    setCurrentPage,
    inputValue,
    setInputValue,
    suggestions,
    setSuggestions,
    suggestionIndex,
    setSuggestionIndex,
    notesModalOpen,
    setNotesModalOpen,
    currentEventId,
    setCurrentEventId,
    // confirmDeleteOpen,
    // setConfirmDeleteOpen,
    showFilter,
    setShowFilter,
    currentFilterUrl,
    setCurrentFilterUrl,
    lastVisible,
    firstRender,
    lastSearchDoc,
    firstSearchDoc,
    dialogSize,
    darkMode,
    firstEventIds,
    setFirstEventIds,
    loading,
    setLoading,
    lastDocs,
    filterTicketQuantity,
    setFilterTicketQuantity,
    soundSettings,
    soundUrls,
    sortOptions,
    setSortOptions,
  } = useTableState();

  const matchingEventIds = Object.entries(eventsInfo).reduce(
    (ids, [eventId, eventInfo]) => {
      if (eventId === searchQuery) {
        return [eventId];
      }
      if (
        eventInfo.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        eventInfo.venue.toLowerCase().includes(searchQuery.toLowerCase()) ||
        eventInfo.date.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        ids.push(eventId);
      }
      return ids;
    },
    []
  );

  useTableEffects(
    user,
    mainUser,
    setLoading,
    firstRender,
    setEmails,
    searchQuery,
    lastVisible,
    currentPage,
    firstEventIds,
    lastDocs,
    matchingEventIds,
    setCurrentPage,
    setFirstEventIds,
    filterTicketQuantity,
    eventInfoRef,
    soundUrls,
    soundSettings,
    sortOptions
  );

  const [siteFilter, setSiteFilter] = useState("");

  const { processedEvents } = useMemo(() => {
    const processedEvents = Object.values(eventsInfo).map((event) => ({
      ...event,
      searchString: event.name.toLowerCase(),
    }));

    const searchIndex = new Map();
    processedEvents.forEach((event) => {
      const words = event.searchString.split(/\s+/);
      words.forEach((word) => {
        if (!searchIndex.has(word)) {
          searchIndex.set(word, new Set());
        }
        searchIndex.get(word).add(event);
      });
    });

    return { searchIndex, processedEvents };
  }, [eventsInfo]);

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
      lastSearchDoc.current = firstSearchDoc.current;
    }
  };

  const handleFilterClick = (url) => {
    setCurrentFilterUrl(url);
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const eventsInfoLower = Object.keys(eventsInfo).reduce((acc, key) => {
    const decode = decodeURIComponent(key);

    const encode = encodeURIComponent(decode);

    acc[encode] = eventsInfo[key];
    return acc;
  }, {});

  const handleClick = async (email) => {
    if (window.tidioChatApi) {
      window.tidioChatApi.display(false);
    }
    const decode = decodeURIComponent(email.eventId);

    const eventId = encodeURIComponent(decode);

    const event_info = eventsInfoLower[eventId];

    const quantity = email.quantity;
    const subject = `${event_info.name} - ${event_info.venue}, ${event_info.date} (${quantity} tickets found)`;

    const html = (
      <EmailContent
        event_info={event_info}
        quantity={quantity}
        map_url={email.map_url}
        groupedTickets={email.groupTickets}
        eventLink={email.eventUrl}
        timestamp={email.timestamp}
        totalSeats={email.totalSeats}
        filtersDocument={filtersDocument[eventId]}
        handleFilterClick={handleFilterClick}
        notesDocument={notesDocument}
        setCurrentEventId={setCurrentEventId}
        setNotesModalOpen={setNotesModalOpen}
        userEmail={mainUser}
        stubhub={stubhub[eventId]}
        eventId={eventId}
        totalEarly={totalEarly}
        early={early}
        editFilters={editFilters}
        email={email}
        priceDrops={email.priceDrop}
        isLowStock={email.lowStock}
        showTable={showTable}
        vividIds={vividIds}
      />
    );
    setSelectedEmail({ email, subject, html });
    dialogSize.current = "md";

    for (let i = 0; i < email.groupTickets.length; i++) {
      const group = email.groupTickets[i];

      if (group.name && group.name.length > 30) {
        dialogSize.current = "lg";
      }

      if (group.seatNumbers && group.seatNumbers.length > 8) {
        dialogSize.current = "lg";
      }
    }

    if (!email.opened) {
      const emailDocRef = doc(db, "emails2", mainUser, "emails", email.uuid);
      await setDoc(emailDocRef, { ...email, opened: true });
      setEmails((prev) =>
        prev.map((prevEmail) => {
          if (prevEmail.uuid === email.uuid) {
            return { ...prevEmail, opened: true };
          } else {
            return prevEmail;
          }
        })
      );
    }

    await updateUserData(user);
  };

  const handleClose = () => {
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
    }
    setSelectedEmail(null);
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleReadAll = async () => {
    try {
      const endpoint = `https://us-central1-phantomchecker.cloudfunctions.net/Read-mailbox`;

      setLoading(true);
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: mainUser }),
      });

      if (!response.ok) {
        console.error("Failed to read mailbox.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const calculateRelevance = (event, searchTokens) => {
    return searchTokens.reduce((relevance, token) => {
      if (event.name.toLowerCase().includes(token)) return relevance + 3;
      return relevance;
    }, 0);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const searchTokens = value
          .split(/\s+/)
          .filter((token) => token.length > 0);

        let matchingEvents = processedEvents;

        if (searchTokens.length > 0) {
          matchingEvents = matchingEvents.filter((event) =>
            searchTokens.some((token) => event.searchString.includes(token))
          );
        }

        const filteredSuggestions = matchingEvents
          .map((event) => ({
            name: event.name,
            relevance: calculateRelevance(event, searchTokens),
          }))
          .sort((a, b) => b.relevance - a.relevance)
          .map((event) => event.name)
          .slice(0, 10);

        setSuggestions(filteredSuggestions);
      }, 50),
    [processedEvents, setSuggestions]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (!value.trim()) {
      setSuggestions([]);
      // setSuggestionIndex(0);
      return;
    }

    debouncedSearch(value.toLowerCase());
  };

  const performSearch = (value) => {
    setSearchQuery(value);
    setSuggestions([]);
    lastSearchDoc.current = null;
    firstSearchDoc.current = null;
  };

  const handleSearchClick = () => {
    setInputValue(suggestions[suggestionIndex]);
    setSuggestions([]);
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchContainer}>
          <TicketQuantityFilter
            onApply={({ ticketQuantity, selectedOption }) => {
              setFilterTicketQuantity(ticketQuantity);
              setSortOptions(selectedOption);
            }}
            darkMode={darkMode}
            sitesFiltered={siteFilter}
            setSiteFilter={setSiteFilter}
          />
          <SearchBar
            darkMode={darkMode}
            searchTerm={inputValue}
            handleInputChange={handleSearchChange}
            handleSearchClick={handleSearchClick}
            suggestions={suggestions}
            suggestionIndex={suggestionIndex}
            setSuggestionIndex={setSuggestionIndex}
            setInputValue={setInputValue}
            inputValue={inputValue}
            performSearch={performSearch}
          />

          <IconButton
            color="inherit"
            disableRipple
            className={classes.iconButton}
          >
            <Link onClick={handleSignOut(db, user)} className={classes.link}>
              <img
                src="/logout.png"
                alt="logout"
                className={classes.logoutIcon}
              />
            </Link>
          </IconButton>

          <Button
            className={classes.Readall}
            onClick={handleReadAll}
            style={{
              backgroundColor: "#670004",
              color: "#fff",
              height: "30px",
              width: "90px",
              marginTop: "50px",
              borderRadius: "9px",
              padding: "0px 10px",
              border: "1px solid #521113",
              textTransform: "none",
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Read All
          </Button>
        </div>

        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <List>
              {emails.map((email, index) => {
                const decode = decodeURIComponent(email.eventId);
                // console.log(email)

                if (siteFilter !== "" && !email.eventUrl.includes(siteFilter))
                  return null;

                const eventId = encodeURIComponent(decode);

                return (
                  <EmailItem
                    key={`${email.eventId}-${index}`}
                    email={email}
                    handleClick={handleClick}
                    event_info={eventsInfoLower[eventId]}
                    updateUserData={async () => {
                      await updateUserData(user);
                    }}
                    qEvent={qEvents.includes(email.eventUrl)}
                  />
                );
              })}
            </List>
            <div className={classes.formPaginationWrapper}>
              <Pagination
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                onNext={handleNext}
                onPrev={handlePrev}
                hasNextPage={true}
                hasPrevPage={currentPage > 0}
              />
            </div>
            {showFilter && !editFilters ? (
              <>
                {mainUser === "test@gmail.com" ||
                mainUser === "concertspecials@gmail.com" ||
                mainUser === "kevinrahmanan@gmail.com" ||
                mainUser === "tridentsolana@gmail.com" ? (
                  <FilterForm
                    eventId={currentFilterUrl[0]}
                    handleClose={handleFilterClose}
                    email={mainUser}
                    fullURL={currentFilterUrl[1]}
                    eventInfo={currentFilterUrl[2]}
                    stubhubId={stubhub[currentFilterUrl[0]]}
                    filter={filtersDocument}
                    formattedFilters={formattedFiltersRef.current}
                  />
                ) : (
                  <FilterFormOld
                    url={currentFilterUrl[0]}
                    handleClose={handleFilterClose}
                    email={mainUser}
                    fullURL={currentFilterUrl[1]}
                    eventInfo={currentFilterUrl[2]}
                    stubhubId={stubhub[currentFilterUrl[0]]}
                    filter={filtersDocument}
                    formattedFilters={formattedFiltersRef.current}
                  />
                )}
              </>
            ) : (
              <Dialog
                open={Boolean(selectedEmail)}
                onClose={handleClose}
                scroll="paper"
                fullWidth
                maxWidth={dialogSize.current}
                PaperProps={{
                  style: {
                    maxHeight: "100%",
                    width: "90%",
                    // minHeight: "60%",
                    overflow: "hidden",
                    borderRadius: "20px",
                    backgroundColor: darkMode ? "#222222" : "",
                    color: darkMode ? "white" : "",
                  },
                }}
              >
                <>
                  <DialogContent
                    dividers
                    style={{
                      backgroundColor: darkMode ? "#222222" : "white",
                    }}
                  >
                    {selectedEmail && <>{selectedEmail.html}</>}
                  </DialogContent>

                  <NotesModal
                    open={notesModalOpen}
                    handleClose={() => setNotesModalOpen(false)}
                    userEmail={mainUser}
                    eventId={currentEventId}
                    notesDocument={notesDocument}
                  />
                </>
              </Dialog>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TicketDrops;
