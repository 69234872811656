import React, { useMemo } from "react";

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Divider = () => (
  <div
    style={{
      height: "30px",
      width: "1px",
      backgroundColor: "#e0e0e0",
    }}
  />
);

const FilterRowComponent = ({
  keyType,
  rowIndex,
  row,
  setData,
  data,
  ticketTypesList,
  rows,
  sections,
  twentyFiveDay,
  totalAvailableStock,
  totalStock,
  darkMode,
  fullURL,
}) => {
  const rowSections = row["sections"];
  const rowTicketTypes = row["ticketTypes"];

  const StockItem = useMemo(
    () =>
      ({ label, value }) =>
        (
          <div
            style={{
              display: "flex",
              marginRight: "15px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontSize: "12px",
                color: darkMode ? "white" : "black",
                marginBottom: "4px",
              }}
            >
              {label}
            </label>
            {React.isValidElement(value) ? (
              value
            ) : (
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#333",
                  padding: "10px",
                  borderRadius: "6px",
                  border: "1px solid #e0e0e0",
                  minWidth: "60px",
                  textAlign: "center",
                }}
              >
                {value}
              </span>
            )}
          </div>
        ),
    [darkMode]
  );

  let totalAvailableStockInSections = 0;

  // console.log("totalAvailableStock", totalAvailableStock);

  for (let x = 0; x < rowSections.length; x++) {
    const section = rowSections[x];

    for (let i = 0; i < totalAvailableStock.length; i++) {
      if (totalAvailableStock[i].section === section) {
        if (rowTicketTypes.includes(totalAvailableStock[i].name)) {
          continue;
        }
        // return totalAvailableStock[i].stock;

        totalAvailableStockInSections += totalAvailableStock[i].stock;
        continue;
      }

      const sectionId = sections.find((s) => s.sectionName === section)?.id;

      if (totalAvailableStock[i].shape === sectionId) {
        if (rowTicketTypes.includes(totalAvailableStock[i].name)) {
          continue;
        }

        totalAvailableStockInSections += totalAvailableStock[i].stock;
        continue;
      }
    }
  }

  const totalStockInSections = rowSections.map(
    (section) => totalStock[section]
  );

  const renderFunctions = {
    totalStock: () => {
      const stockMonitor = row["Stock Monitor"];
      const totalSections = totalStockInSections.reduce((a, b) => a + b, 0);

      const stockValue = row["totalStock"];
      const totalAvailable = totalAvailableStockInSections || 0;

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // gap: "15px",
            padding: "10px",
            borderRadius: "8px",
            boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
          }}
        >
          <StockItem
            label="Total Stock"
            value={
              <TextField
                value={stockValue ?? ""}
                onChange={(e) => {
                  const newData = [...data];
                  newData[rowIndex]["totalStock"] = e.target.value;
                  setData(newData);
                }}
                type="number"
                disabled={!stockMonitor}
                InputProps={{
                  style: {
                    width: "80px",
                    borderRadius: "6px",
                    color: "#333",
                    backgroundColor: stockMonitor ? "#ffffff" : "#ffcccc",
                    transition: "all 0.3s ease",
                  },
                }}
              />
            }
          />
          <Divider />
          <StockItem label="Available Stock" value={totalAvailable} />
          <Divider />
          <StockItem label="Total Section Stock" value={totalSections} />
        </div>
      );
    },
    prices: () => (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        ml={-2}
      >
        {row["prices"]?.map((price, index) => (
          <Box display="flex" alignItems="center" key={index} mb={1}>
            <Box mr={1}>$</Box>
            <TextField
              value={
                twentyFiveDay
                  ? "15"
                  : row["excludeFilter"]
                  ? ""
                  : price?.min ?? ""
              }
              onChange={(e) => {
                if (!twentyFiveDay) {
                  const newData = [...data];
                  newData[rowIndex]["prices"][index].min = e.target.value;
                  setData(newData);
                }
              }}
              disabled={
                row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
              }
              style={{
                minWidth: "70px",
                maxWidth: "100px",
                border:
                  row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
                    ? "1px solid red"
                    : "",
                borderRadius:
                  row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
                    ? "5px"
                    : "",
              }}
              error={price?.min && !/^\d+$/.test(price.min.replace(".", ""))}
            />
            <Box mx={1}>-</Box>
            <TextField
              value={
                twentyFiveDay
                  ? "22"
                  : row["excludeFilter"]
                  ? ""
                  : price?.max ?? ""
              }
              onChange={(e) => {
                if (!twentyFiveDay) {
                  const newData = [...data];
                  newData[rowIndex]["prices"][index].max = e.target.value;
                  setData(newData);
                }
              }}
              disabled={
                row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
              }
              style={{
                minWidth: "70px",
                maxWidth: "100px",
                border:
                  row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
                    ? "1px solid red"
                    : "",
                borderRadius:
                  row["excludeFilter"] || twentyFiveDay || row["Stock Monitor"]
                    ? "5px"
                    : "",
              }}
              error={price?.max && !/^\d+$/.test(price.max.replace(".", ""))}
            />
            {row["prices"].length > 1 && !twentyFiveDay && (
              <IconButton
                size="small"
                onClick={() => {
                  const newData = [...data];
                  newData[rowIndex]["prices"].splice(index, 1);
                  setData(newData);
                }}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ))}
        {!twentyFiveDay && (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            mt={2}
            paddingRight={5}
            height={10}
          >
            <IconButton
              size="small"
              onClick={() => {
                const newData = [...data];
                if (!newData[rowIndex]["prices"]) {
                  newData[rowIndex]["prices"] = [];
                }
                newData[rowIndex]["prices"].push({ min: "", max: "" });
                setData(newData);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    ),
    numSeats: () => (
      <Select
        value={row["excludeFilter"] ? "" : row[keyType]}
        onChange={(e) => {
          const newData = [...data];
          newData[rowIndex][keyType] = e.target.value;
          setData(newData);
        }}
        disabled={row["excludeFilter"] || row["Stock Monitor"]}
        style={{
          border:
            row["excludeFilter"] || row["Stock Monitor"] ? "1px solid red" : "",
          borderRadius:
            row["excludeFilter"] || row["Stock Monitor"] ? "5px" : "",
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {[1, 2, 3, 4, 5, 6].map((num) => [
          <MenuItem
            key={num}
            value={num}
            style={{
              display: "block",
              width: "100%",
            }}
          >
            {num}+
          </MenuItem>,
        ])}
      </Select>
    ),
    ticketTypes: () => (
      <FormControl style={{ width: "200px" }}>
        <InputLabel>Exclude Ticket types</InputLabel>
        <Select
          multiple
          value={row["excludeFilter"] ? [] : row[keyType]}
          onChange={(e) => {
            const newData = [...data];
            newData[rowIndex][keyType] = e.target.value;
            setData(newData);
          }}
          input={<OutlinedInput label="Ticket types" />}
          renderValue={(selected) => selected.join(", ")}
          disabled={row["excludeFilter"]}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          style={{
            border: row["excludeFilter"] ? "1px solid red" : "",
            borderRadius: row["excludeFilter"] ? "5px" : "",
          }}
        >
          {ticketTypesList.map((type) => [
            <MenuItem
              key={type}
              value={type}
              selected={row[keyType]?.includes(type)}
              style={{
                backgroundColor: row[keyType]?.includes(type)
                  ? "#ffcccc"
                  : "transparent",
                display: "block",
                width: "100%",
              }}
            >
              {type}
            </MenuItem>,
          ])}
        </Select>
      </FormControl>
    ),
    rows: () => (
      <FormControl style={{ width: "200px" }}>
        <InputLabel>Rows</InputLabel>
        <Select
          multiple
          value={row[keyType] || []}
          disabled={row["Stock Monitor"]}
          onChange={(e) => {
            const newData = [...data];
            newData[rowIndex][keyType] = e.target.value;
            setData(newData);
          }}
          style={{
            border: row["Stock Monitor"] ? "1px solid red" : "",
            borderRadius: row["Stock Monitor"] ? "5px" : "",
          }}
          input={<OutlinedInput label="Rows" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "600px",
                width: "20ch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              },
            },
          }}
        >
          {rows &&
            rows.length > 0 &&
            rows.map((type) => [
              <MenuItem
                key={type}
                value={type}
                selected={row[keyType]?.includes(type)}
                style={{ display: "block", width: "100%" }}
              >
                {type}
              </MenuItem>,
            ])}
        </Select>
      </FormControl>
    ),
    sections: () => (
      <FormControl style={{ width: "200px" }}>
        <InputLabel>Sections</InputLabel>
        <Select
          multiple
          value={row[keyType] || []}
          onChange={(e) => {
            const newData = [...data];
            newData[rowIndex][keyType] = e.target.value;
            setData(newData);
          }}
          input={<OutlinedInput label="Sections" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "600px",
                width: "20ch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              },
            },
          }}
        >
          {sections &&
            sections.length > 0 &&
            sections.map((section) => [
              <MenuItem
                key={section.id}
                value={section.sectionName}
                selected={row[keyType]?.includes(section.sectionName)}
                style={{ display: "block", width: "100%" }}
              >
                {section.sectionName}
              </MenuItem>,
            ])}
        </Select>
      </FormControl>
    ),
    excludeFilter: () => (
      <FormControl>
        <Select
          id="exclude-filter-select"
          value={
            row["Stock Monitor"]
              ? "Stock Monitor"
              : row[keyType]
              ? "Exclude Filter"
              : ""
          }
          onChange={(e) => {
            const value = e.target.value;

            if (value === "") {
              const newData = [...data];

              delete newData[rowIndex][keyType];
              delete newData[rowIndex]["Stock Monitor"];

              setData(newData);
              return;
            }
            if (value === "Stock Monitor") {
              const newData = [...data];

              delete newData[rowIndex][keyType];

              newData[rowIndex]["Stock Monitor"] = true;
              setData(newData);
              return;
            } else {
              const newData = [...data];

              delete newData[rowIndex]["Stock Monitor"];

              newData[rowIndex][keyType] = true;
              setData(newData);
            }
          }}
          placeholder="Exclude Filter"
          disabled={rowIndex === 0}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "600px",
                width: "12ch",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              },
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"Exclude Filter"}>Exclude Filter</MenuItem>
          {!fullURL.includes("axs.com") &&
          !fullURL.includes("mlb.tickets.com") &&
          !fullURL.includes("mpv.tickets.com") &&
          !fullURL.includes("seatgeek.com") ? (
            <MenuItem value={"Stock Monitor"}>Stock Monitor</MenuItem>
          ) : null}
        </Select>
      </FormControl>
    ),
  };

  return (
    <>
      {renderFunctions[keyType] ? renderFunctions[keyType]() : <h1>error</h1>}
    </>
  );
};

export default FilterRowComponent;
