import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import logo from "../../assets/PhantomLogo.png";

const WindowsDownloadLink =
  "https://mg.phantomcheckerapi.com/api-internal/extra/download-windows";
const MacDownloadLink =
  "https://mg.phantomcheckerapi.com/api-internal/extra/download-mac";
const AndroidDownloadLink =
  "https://mg.phantomcheckerapi.com/api-internal/extra/download-android";
const IosDownloadLink = "https://testflight.apple.com/join/lWZ3EuTM";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#222222",
    textAlign: "center",
    borderRadius: "20px",
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  "& .logoContainer": {
    maxWidth: "137px",
    margin: "0 auto 23px",
    "& img": {
      width: "100%",
    },
  },
  "& .MuiDialogTitle-root": {
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: 0,
    paddingTop: "30px",
  },
  "& .MuiDialogTitle-root + .MuiDialogContent-root p": {
    color: "#e3e3e3",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "space-between",
    padding: "0 46px 30px",
    "& a": {
      backgroundColor: "#171717",
      border: "1px solid #bfbfbf",
      padding: "0.75px 28px",
      borderRadius: "10px",
      color: "#e3ad00",
      margin: 0,
      fontSize: "14px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      margin: "10px",
      width: "calc(100% - 20px)",
      maxWidth: "90%",
    },
    "& .logoContainer": {
      maxWidth: "80%",
    },
  },
}));

const DownloadAppDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button
        type="button"
        className="download-app-button"
        onClick={handleClickOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Download App
      </button>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle style={{ color: "white" }}>
          <div className="logoContainer">
            <img src={logo} alt="Logo" />
          </div>
          Download Application
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose your platform to download the desktop application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            href={WindowsDownloadLink}
            color="primary"
            style={{
              marginLeft: "10px",
            }}
          >
            Windows
          </Button>
          <Button
            style={{
              marginLeft: "10px",
            }}
            onClick={handleClose}
            href={MacDownloadLink}
            color="primary"
          >
            Mac
          </Button>
          <Button
            style={{
              marginLeft: "10px",
            }}
            onClick={handleClose}
            href={IosDownloadLink}
            color="primary"
          >
            iOS
          </Button>
          <Button
            style={{
              marginLeft: "10px",
            }}
            onClick={handleClose}
            href={AndroidDownloadLink}
            color="primary"
          >
            Android
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default DownloadAppDialog;
