import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export const fetchSectionRow = async (
  fullURL,
  setRows,
  setSections,
  setInfo,
  setMapid,
  setPaths,
  setTotalStock,
  setAxsMapInfo
) => {
  try {
    const q = query(collection(db, "map_info"), where("url", "==", fullURL));

    onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs[0].data();

        if (
          data.unique_row.length === 0 ||
          data.unique_sections.length === 0 ||
          !data.unique_row ||
          !data.unique_sections ||
          fullURL.includes("axs.com")
        ) {
          fetchDataFromAPI();
        }

        setRows(data.unique_row || []);

        data.unique_sections.sort((a, b) => {
          try {
            if (parseInt(a.sectionName) < parseInt(b.sectionName)) {
              return -1;
            }
            if (parseInt(a.sectionName) > parseInt(b.sectionName)) {
              return 1;
            }
          } catch (error) {
            console.error("Error sorting sections:", error);
          }

          if (a.sectionName < b.sectionName) {
            return -1;
          }
          if (a.sectionName > b.sectionName) {
            return 1;
          }
          return 0;
        });

        data.unique_row.sort((a, b) => {
          const isANumber = !isNaN(a);
          const isBNumber = !isNaN(b);
          if (isANumber && isBNumber) {
            return a - b;
          }
          if (isANumber) {
            return -1;
          }
          if (isBNumber) {
            return 1;
          }
          if (a.length !== b.length) {
            return a.length - b.length;
          }
          return a.localeCompare(b);
        });
        setSections(data.unique_sections || []);
        setInfo(data.info || []);
        setMapid(data.mapUrl || "");
        setPaths(data.paths || []);
        setTotalStock(data.sectionsStock || {});

        if (data.svgItems && data.baseImage) {
          setAxsMapInfo({
            svgItems: data.svgItems,
            baseImage: data.baseImage,
            transform: data.transform || false,
          });
        }
      } else {
        fetchDataFromAPI();
      }
    });

    const fetchDataFromAPI = async () => {
      try {
        let fetchUrl = "https://mg.phantomcheckerapi.com/api/ticketmaster/map";
        if (
          fullURL.includes("mlb.tickets.com") ||
          fullURL.includes("mpv.tickets.com")
        ) {
          fetchUrl = "https://mg.phantomcheckerapi.com/api/mlb/map";
        }
        if (fullURL.includes("seatgeek.com")) {
          fetchUrl = "https://mg.phantomcheckerapi.com/api/seatgeek/map";
        }
        if (fullURL.includes("axs.com")) {
          fetchUrl = "https://mg.phantomcheckerapi.com/api/axs/map";
        }
        fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: fullURL,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            data.unique_sections.sort((a, b) => {
              if (a.sectionName < b.sectionName) {
                return -1;
              }
              if (a.sectionName > b.sectionName) {
                return 1;
              }
              return 0;
            });

            data.unique_row.sort((a, b) => {
              const isANumber = !isNaN(a);
              const isBNumber = !isNaN(b);
              if (isANumber && isBNumber) {
                return a - b;
              }
              if (isANumber) {
                return -1;
              }
              if (isBNumber) {
                return 1;
              }
              if (a.length !== b.length) {
                return a.length - b.length;
              }
              return a.localeCompare(b);
            });

            setRows(data.unique_row || []);
            setSections(data.unique_sections || []);
            setInfo(data.info || []);
            setMapid(data.mapUrl || "");
            setPaths(data.paths || []);
          });
      } catch (error) {
        console.error("Error fetching data from API:", error);
        setRows([]);
        setSections([]);
      }
    };
  } catch (error) {
    console.error("Error fetching section and row data:", error);
    setRows([]);
    setSections([]);
  }
};

export const fetchTicketTypes = async (fullURL) => {
  try {
    let url = "https://mg.phantomcheckerapi.com/api/ticketmaster/ticket-type";
    if (fullURL.includes("axs.com")) {
      url = "https://mg.phantomcheckerapi.com/api/axs/ticket-type";
    }
    if (fullURL.includes("seatgeek.com")) {
      url = "https://mg.phantomcheckerapi.com/api/seatgeek/ticket-type";
    }

    if (
      fullURL.includes("ticketmaster.be") ||
      fullURL.includes("ticketmaster.dk") ||
      fullURL.includes("ticketmaster.de") ||
      fullURL.includes("ticketmaster.nl") ||
      fullURL.includes("ticketmaster.fi") ||
      fullURL.includes("ticketmaster.no") ||
      fullURL.includes("ticketmaster.se") ||
      fullURL.includes("ticketmaster.at") ||
      fullURL.includes("ticketmaster.ae") ||
      fullURL.includes("ticketmaster.pl") ||
      fullURL.includes("ticketmaster.es") ||
      fullURL.includes("ticketmaster.ch") ||
      fullURL.includes("ticketmaster.it") ||
      fullURL.includes("ticketmaster.cz") ||
      fullURL.includes("ticketmaster.co.za")
    ) {
      url = "https://mg.phantomcheckerapi.com/api/ticketmaster/ticket-type-eu";
    }
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: fullURL,
      }),
    });
  } catch (error) {
    console.error("Error fetching ticket types:", error);
  }
};

export const handleSvgPathClick = (path, setSelectedPaths) => {
  setSelectedPaths((prevPaths) => {
    // Handling list of sections
    if (Array.isArray(path)) {
      let newPaths = [...prevPaths];
      path.forEach((section) => {
        if (newPaths.includes(section.name)) {
          newPaths = newPaths.filter((p) => p !== section.name);
        } else {
          newPaths.push(section.name);
        }
      });
      return newPaths;
    }

    // Handling single section
    if (prevPaths.includes(path.name)) {
      return prevPaths.filter((p) => p !== path.name);
    }
    return [...prevPaths, path.name];
  });
};

export const organizeSections = (sections) => {
  if (!sections || !Array.isArray(sections)) {
    return;
  }

  let organizedSections = {};

  // Group sections by prefix
  sections.forEach((section) => {
    // Check for valid section
    if (!section || typeof section !== "object" || !section.name) {
      return;
    }

    let name = section.name;
    let specialPrefixMatch = name.match(/^(\d+)(ST|ND|RD|TH)/);
    let numberPrefix = name.match(/^\d+/);
    let letterPrefix = name.match(/^[a-zA-Z]+/);
    let wheelchairSection = name.startsWith("W");

    if (specialPrefixMatch) {
      let specialPrefix = specialPrefixMatch[0]; // Extracts "1ST", "2ND", etc.
      if (!organizedSections[specialPrefix]) {
        organizedSections[specialPrefix] = [];
      }
      organizedSections[specialPrefix].push(section);
      return;
    }

    if (numberPrefix && !specialPrefixMatch) {
      let key = Math.floor(parseInt(numberPrefix[0]) / 100) * 100 + "s";
      if (!organizedSections[key]) {
        organizedSections[key] = [];
      }
      organizedSections[key].push(section);
      return;
    }

    // Group by wheelchair sections
    if (wheelchairSection) {
      let key = "Wheelchair";
      if (!organizedSections[key]) {
        organizedSections[key] = [];
      }
      organizedSections[key].push(section);
      return;
    }

    // Group by letter prefix (excluding W which we've already grouped as wheelchair)
    if (letterPrefix && letterPrefix[0].toUpperCase() !== "W") {
      let key = letterPrefix[0].toUpperCase();
      if (!organizedSections[key]) {
        organizedSections[key] = [];
      }
      organizedSections[key].push(section);
      return;
    }
  });

  // Extract the sections not grouped by prefix
  let remainingSections = sections.filter((section) => {
    if (!section || typeof section !== "object" || !section.name) {
      return false;
    }

    let name = section.name;
    let numberPrefix = name.match(/^\d+/);
    let letterPrefix = name.match(/^[a-zA-Z]+/);
    let wheelchairSection = name.startsWith("W");

    return (
      !numberPrefix &&
      !letterPrefix &&
      !wheelchairSection &&
      !/^(\d+)(ST|ND|RD|TH)/.test(name)
    );
  });

  // Group remaining sections by common words
  while (remainingSections.length > 0) {
    let currentSection = remainingSections.pop();
    if (
      !currentSection ||
      typeof currentSection !== "object" ||
      !currentSection.name
    ) {
      continue;
    }

    let matchedGroupKey = null;

    // Check if this section matches any existing non-numeric group
    for (let key in organizedSections) {
      if (
        !/^\d+s$/.test(key) &&
        !/^[a-zA-Z]$/.test(key) &&
        key !== "Wheelchair" &&
        currentSection.name.includes(key)
      ) {
        matchedGroupKey = key;
        break;
      }
    }

    // If matched with an existing group
    if (matchedGroupKey) {
      organizedSections[matchedGroupKey].push(currentSection);
    } else {
      let commonWord = currentSection.name.split(" ")[0];
      if (!organizedSections[commonWord]) {
        organizedSections[commonWord] = [];
      }
      organizedSections[commonWord].push(currentSection);
    }
  }

  // Organize keys in alphabetical order
  let orderedSections = {};
  Object.keys(organizedSections)
    .sort()
    .forEach((key) => {
      orderedSections[key] = organizedSections[key];
    });

  return orderedSections;
};
