import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const FilterHeaders = ({
  fullURL,
  name,
  date,
  venue,
  priceRange,
  stubhubId,
  makeFilterSectionTypes,
  data,
}) => {
  const theme = useTheme();
  const isAxs = fullURL.includes("axs.com");
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginBottom={3}
      >
        <Card
          variant="outlined"
          style={{
            textAlign: "center",
            width: "90%",
            borderRadius: "15px",
            boxShadow: "0 3px 5px 2px rgba(125, 123, 135, .3)",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <CardContent>
            <a
              href={fullURL}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                style={{
                  color: "#3f51b5",
                  fontFamily: "Arial, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {name}
              </Typography>
            </a>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ fontFamily: "Courier, monospace" }}
            >
              {isAxs
                ? date
                : new Date(date).toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ fontFamily: "Courier, monospace" }}
            >
              Venue: {venue}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ fontFamily: "Courier, monospace" }}
            >
              Price Range: {priceRange}
            </Typography>
            {stubhubId && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href={`https://www.stubhub.com/event/${stubhubId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="stubhublogo.jpg"
                    alt="stubhub"
                    width="70"
                    height="40"
                  ></img>
                </a>
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogContentText style={{ transform: "translateY(-50%)" }}>
          You can add filters here. Please separate multiple values with a
          comma.
        </DialogContentText>
        <Button
          variant="outlined"
          color="primary"
          onClick={makeFilterSectionTypes}
          disabled={Object.keys(data).length > 1}
        >
          By Section Types
        </Button>
      </div>
    </>
  );
};

export default FilterHeaders;
