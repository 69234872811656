import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import * as fflate from "fflate";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { BuildSeats } from "./utils/buildSeats";
import CustomizableTable from "./components/SeatDataTable";
import { Button, Modal, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const PhantomLogs = ({ event_id }) => {
  const [map, setMap] = useState({});
  const [logs, setLogs] = useState(null);
  const [changes, setChanges] = useState({});
  const [selectedTimeStamp, setSelectedTimeStamp] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (event_id === null || event_id === undefined) return;
    console.log("event", event_id);
    const fetchMapData = async () => {
      try {
        const mapDocRef = doc(db, "MapData", event_id);
        const docSnapshot = await getDoc(mapDocRef);
        if (docSnapshot.exists()) {
          const docData = docSnapshot.data();
          if (docData && docData.map) {
            const mapBlob = docData.map;
            const mapBlobArrayBuffer = mapBlob.toUint8Array
              ? mapBlob.toUint8Array()
              : new Uint8Array(mapBlob);
            try {
              const decompressed = fflate.gunzipSync(mapBlobArrayBuffer);
              const mapData = JSON.parse(
                new TextDecoder().decode(decompressed)
              );
              setMap(mapData);
            } catch (error) {
              console.error("Decompression error:", error);
            }
          } else {
            console.log("Map data not found in the document!");
          }
        } else {
          console.log("Document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };
    fetchMapData();

    const docRef = doc(db, "TicketLogs", event_id);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (logs === null) {
          // setLogs(doc.data().initialSeats || []);
          if (data && data.initialSeats) {
            setLogs(data.initialSeats || []);
          }
        }
        if (data && data.changes) {
          setChanges(data.changes || {});
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [event_id, logs]);

  const seatData = BuildSeats(logs, map, changes, selectedTimeStamp);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const ModalContent = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "80vh",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
  }));

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        View Seat Data
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <Typography variant="h5" gutterBottom>
            Seat Data
          </Typography>
          <CustomizableTable
            data={seatData}
            changes={changes}
            onSelectedTimeStampChange={setSelectedTimeStamp}
            selectedTimeStamp={selectedTimeStamp}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PhantomLogs;
