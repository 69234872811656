import { collection, doc, writeBatch } from "firebase/firestore";
import { auth, db } from "../../../firebase";

export function handleAddRow(defaultData, tableState, setTableState, twentyFiveDay, isExculdeFilter = false, isStockMonitor = false) {
  const tickets = [
    "Verified Resale",
    "Official Platinum",
    "Special Offers",
    "VIP",
    "Wheel Chair",
    "Make A Difference Ticket",
    "Platinum",
    "Diamond",
    "Gold",
    "Silver",
    "Resale",
    "Premium",
    "Prime",
    "Accessibility",
  ];

  if (twentyFiveDay) tickets.push("Standard");

  const additionalTicketTypes = tableState.ticketTypesList.filter((type) => tickets.some((vipType) => type.includes(vipType)));

  const newData = {
    ...defaultData,
    excludeFilter: isExculdeFilter,
    "Stock Monitor": isStockMonitor,
    ticketTypes: additionalTicketTypes.length > 0 ? additionalTicketTypes : defaultData.ticketTypes,
  };

  setTableState({ data: [...tableState.data, newData], selectedRow: tableState.data.length, selectedPaths: [] });
}

export async function handleSubmit(e, tableState, email, eventId, onClose, formattedFilters, fullURL) {
  e.preventDefault();

  try {
    /* convert the data to a filter format */
    const filters = tableState.data.reduce((filters, current, index) => {
      const sections = tableState.sections.filter((section) => current.sections.includes(section.sectionName)).map((section) => section.id);
      const timestamp = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
      const randomId = Math.floor(Math.random() * 1000000);

      filters[`row${index + 1}`] = { ...current, sectionIds: sections, timestamp, id: randomId };
      return filters;
    }, {});

    /* ensure minimum and maximum price values are different */
    const isInvalidPriceRange = tableState.data.some((item) => item.prices.some((price) => Number(price.min) === Number(price.max) && price.max !== ""));
    if (isInvalidPriceRange) {
      alert("Minimum and maximum prices cannot be the same.");
      return;
    }

    let targetDocID = null;
    let docWithSpace = null;

    /* determine if there is an existing document to modify or not */
    for (let [docId, filterData] of Object.entries(formattedFilters)) {
      if (filterData.hasOwnProperty(eventId)) {
        targetDocID = docId;
      } else if (!docWithSpace && Object.keys(filterData).length < 100) {
        docWithSpace = docId;
      }
    }

    /* update the filter collection with the new data */
    const batch = writeBatch(db);
    const filterCollection = collection(db, "filters4");
    const docId = `${email}-${eventId}`;
    const urlLogsDoc = doc(db, "UrlLogs", docId);

    if (targetDocID) {
      const docToUpdate = doc(filterCollection, targetDocID);
      batch.update(docToUpdate, { [`filter.${eventId}`]: filters, last_modified: new Date() });
    } else if (docWithSpace) {
      const docToUpdate = doc(filterCollection, docWithSpace);
      batch.update(docToUpdate, { [`filter.${eventId}`]: filters, last_modified: new Date() });
    } else {
      const newDocRef = doc(filterCollection);
      batch.set(newDocRef, { filter: { [eventId]: filters }, last_modified: new Date(), emailAddress: email });
    }

    /* update the url logs document */
    const timestamp = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    const filter = { e: fullURL, u: auth.currentUser.email };
    batch.set(urlLogsDoc, { urlFilters: { [timestamp]: filter } }, { merge: true });

    await batch.commit();
    onClose();
  } catch (error) {
    console.error("Error handling submission: ", error);
  }
};
