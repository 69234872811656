import React from "react";

import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import FilterRowComponent from "./filterRow";

const FilterInfo = ({
  handleSubmit,
  handleAddRow,
  handleRemoveRow,
  clearAll,
  data,
  defaultData,
  ticketTypesList,
  rows,
  sections,
  darkMode,
  selectedRow,
  setSelectedRow,
  setSelectedPaths,
  setData,
  twentyFiveDay,
  totalStock,
  totalAvailableStock,
  mainUser,
  fullURL,
}) => {
  const CheckIfStockMonitored = data.reduce((acc, curr) => {
    if (curr["Stock Monitor"]) {
      return true;
    }
    return acc;
  }, false);

  if (CheckIfStockMonitored) {
    if (!defaultData["totalStock"]) {
      defaultData = {
        excludeFilter: false,
        sections: [],
        rows: [],
        prices: [{ min: 0, max: "" }],
        totalStock: {},
        numSeats: 2,
        ticketTypes: [],
      };
    }
  } else {
    delete defaultData["totalStock"];
  }

  return (
    <form onSubmit={handleSubmit}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filter Option</TableCell>
              <TableCell>Sections</TableCell>
              <TableCell>Rows</TableCell>
              <TableCell>Price Range</TableCell>
              {CheckIfStockMonitored ? (
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  Stock Monitor
                </TableCell>
              ) : null}
              <TableCell>Minimum Number seats</TableCell>
              <TableCell>Exclude Ticket types</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => {
                  setSelectedRow(rowIndex);
                  setSelectedPaths(data[rowIndex].sections || []);
                }}
                style={{
                  backgroundColor:
                    rowIndex === selectedRow
                      ? darkMode
                        ? "#007acc"
                        : "#e0f7fa"
                      : "",
                  cursor: "pointer",
                }}
              >
                {Object.keys(defaultData).map((key, i) => (
                  <TableCell key={i}>
                    <FilterRowComponent
                      keyType={key}
                      totalAvailableStock={totalAvailableStock}
                      rowIndex={rowIndex}
                      row={row}
                      setData={setData}
                      data={data}
                      ticketTypesList={ticketTypesList}
                      rows={rows}
                      sections={sections}
                      twentyFiveDay={twentyFiveDay}
                      totalStock={totalStock}
                      mainUser={mainUser}
                      darkMode={darkMode}
                      fullURL={fullURL}
                    />
                  </TableCell>
                ))}
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => handleRemoveRow(rowIndex)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="space-between" marginY={2}>
        <Button variant="outlined" color="primary" onClick={clearAll}>
          Clear All
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <IconButton
          color="primary"
          onClick={handleAddRow}
          style={{ color: "blue" }}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </IconButton>
      </Box>
    </form>
  );
};

export default FilterInfo;
