import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import DownloadAppDialog from "./DownloadApp";

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  handleSubmit,
  handleWhopLogin,
  setShowResetPassword,
}) => (
  <form onSubmit={handleSubmit} className="login-form">
    <input
      type="email"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
      autoFocus
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    <button type="submit">Login</button>
    <div className="or">or</div>
    <button
      type="button"
      onClick={() => {
        window.open(`https://whop.com/phantom-checker/`, "_blank");
      }}
    >
      Purchase Product
    </button>
    <button type="button" onClick={handleWhopLogin}>
      Login with Whop
    </button>
    {/* <button type="button" >
      Forgot Password
    </button> */}
    <DownloadAppDialog />
    <div className="forgotString">
      Forgot password?{" "}
      <span onClick={() => setShowResetPassword(true)}>Reset here</span>
    </div>
  </form>
);

const ResetPasswordForm = ({
  email,
  setEmail,
  setShowResetPassword,
  setPassword,
  setError,
}) => {
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);

      alert(
        "Password reset email sent! Please check your email to reset your password."
      );

      setEmail("");
      setPassword("");
      setShowResetPassword(false);
      setError("");
    } catch (error) {
      setError(
        "Failed to send password reset email. Please check your email address."
      );
    }
  };

  return (
    <form onSubmit={handleResetPassword} className="login-form">
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        autoFocus
      />
      <button type="submit">Reset Password</button>
      <button
        type="button"
        className="backToLogin"
        onClick={() => setShowResetPassword(false)}
      >
        Back to Login
      </button>
    </form>
  );
};

export { LoginForm, ResetPasswordForm };
