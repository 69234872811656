import { useState } from "react";
import { useTheme } from "../../ThemeContext";

export const useTableState = () => {
  const [ticketTypesList, setTicketTypesList] = useState([]);

  const [rows, setRows] = useState([]);
  const [sections, setSections] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedPaths, setSelectedPaths] = useState([]);
  const [paths, setPaths] = useState([]);
  const [info, setInfo] = useState({});
  const [mapid, setMapid] = useState("");

  const [selectedRow, setSelectedRow] = useState(0);
  const [totalStock, setTotalStock] = useState({});

  const [totalAvailableStock, setTotalAvailableStock] = useState({});
  const [axsMapInfo, setAxsMapInfo] = useState(undefined);

  const [data, setData] = useState([]);

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
    useState(false);

  const { darkMode } = useTheme();

  const defaultData = {
    excludeFilter: false,
    sections: [],
    rows: [],
    prices: [{ min: 0, max: "" }],
    numSeats: 2,
    ticketTypes: [],
  };

  return {
    totalAvailableStock,
    setTotalAvailableStock,
    totalStock,
    setTotalStock,
    ticketTypesList,
    setTicketTypesList,
    rows,
    setRows,
    sections,
    setSections,
    filtersApplied,
    setFiltersApplied,
    selectedPaths,
    setSelectedPaths,
    paths,
    setPaths,
    info,
    setInfo,
    mapid,
    setMapid,
    selectedRow,
    setSelectedRow,
    data,
    setData,
    isNotificationDialogOpen,
    setIsNotificationDialogOpen,
    darkMode,
    defaultData,
    axsMapInfo,
    setAxsMapInfo,
  };
};
