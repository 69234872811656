import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";

// const styles = {
//   tableContainer: {
//     textAlign: "center",
//     borderRadius: "10px",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     fontFamily: "'Lora', serif",
//     backgroundColor: "#7f68a0",
//     marginLeft: "10px",
//   },
//   tableHeaderCell: {
//     fontWeight: "bold",
//     backgroundColor: "#333",
//     color: "white",
//     padding: "8px 5px",
//     whiteSpace: "nowrap",
//     fontSize: "13px",
//   },
//   tableRow: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: "#f2f2f2",
//     },
//   },
//   tableCellContent: {
//     fontSize: "16px",
//     lineHeight: "1", // Adjusted line height
//     color: "#333",
//     padding: "1px 1px", // Reduced padding
//     fontWeight: "bold",
//   },
// };

const StubHubTable = ({ stubhubData, groupedTickets, note, darkMode }) => {
  const convertToClickableLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #007BFF; text-decoration: underline;">${url}</a>`
    );
  };

  if (!stubhubData) {
    return (
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#F5F5F5",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          fontFamily: "'Lora', serif",
          marginTop: "20px",
          flex: 1,
        }}
      >
        <strong
          style={{
            fontSize: "20px",
            borderBottom: "2px solid #333",
            paddingBottom: "10px",
            display: "block",
            marginBottom: "10px",
            color: "#333",
          }}
        >
          Notes:
        </strong>
        <span
          dangerouslySetInnerHTML={{
            __html: convertToClickableLink(note),
          }}
          style={{ fontSize: "16px", lineHeight: "1.5", color: "#333" }}
        />
      </div>
    );
  }

  return null;

  // return (
  //   <div style={{ flex: 1 }}>
  //     <h4
  //       style={{
  //         textAlign: "center",
  //         marginTop: "5px",
  //         marginBottom: "5px",
  //         height: 20,
  //         color: darkMode ? "rgb(171 118 253)" : "#6647BA",
  //         textDecoration: "underline",
  //       }}
  //     >
  //       Phantom Data
  //     </h4>
  //     <TableContainer component={Paper} style={styles.tableContainer}>
  //       <Table style={styles.table}>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell style={styles.tableHeaderCell}>
  //               Section Price
  //             </TableCell>
  //             <TableCell style={styles.tableHeaderCell}>
  //               Cheapest In Level
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {groupedTickets.map((group, index) => {
  //             let sectionGetIn = "";
  //             let sectionLevelGetIn = "";
  //             const sectionLevels = stubhubData?.total_data;

  //             if (sectionLevels) {
  //               for (const category in sectionLevels) {
  //                 if (Object.hasOwnProperty.call(sectionLevels, category)) {
  //                   const sections = sectionLevels[category];

  //                   for (const section of sections) {
  //                     const { t, min } = section;

  //                     if (t && t === group.sectionName) {
  //                       sectionGetIn = min;

  //                       const minArray = sections
  //                         .map((section) => {
  //                           const minValue = parseFloat(
  //                             section.min.replace(/[$,]/g, "")
  //                           );
  //                           return !isNaN(minValue) ? minValue : null;
  //                         })
  //                         .filter((min) => min !== null && min !== 0);

  //                       if (minArray.length > 0) {
  //                         sectionLevelGetIn = `$${Math.min(...minArray).toFixed(
  //                           2
  //                         )}`;
  //                       } else {
  //                         sectionLevelGetIn = "$0";
  //                       }

  //                       break;
  //                     }
  //                   }
  //                 }
  //               }
  //             }

  //             return (
  //               <TableRow key={index} style={styles.tableRow}>
  //                 <TableCell
  //                   sx={{
  //                     borderBottom: "none",
  //                     paddingTop: 1.3,
  //                     paddingBottom: 1.3,
  //                     borderRight: "1px solid black",
  //                   }}
  //                 >
  //                   <span style={styles.tableCellContent}>{sectionGetIn}</span>
  //                 </TableCell>
  //                 <TableCell
  //                   sx={{
  //                     borderBottom: "none",
  //                     paddingTop: 1.3,
  //                     paddingBottom: 1.3,
  //                   }}
  //                 >
  //                   <span style={styles.tableCellContent}>
  //                     {sectionLevelGetIn}
  //                   </span>
  //                 </TableCell>
  //               </TableRow>
  //             );
  //           })}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   </div>
  // );
};

export default StubHubTable;
