import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { styled } from "@mui/material/styles";
import { handleAddUrl } from "../HomePage/handlers/urlManipulation";
import { useEventContext } from "../HomePage/UserDataContext";
import moment from "moment-timezone";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
  },
});

const AddEventButton = styled("button")(({ theme }) => ({
  background: "transparent",
  border: "none",
  color: theme.palette.primary.main,
  padding: "4px 8px",
  fontSize: "0.875rem",
  fontWeight: 500,
  textTransform: "uppercase",
  cursor: "pointer",
  transition: "all 0.2s ease",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "2px",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transformOrigin: "bottom right",
    transition: "transform 0.3s ease",
  },
  "&:hover": {
    color: theme.palette.primary.dark,
    "&:after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  tabs: {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, .1)",
  },
  viewSelect: {
    minWidth: 120,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  eventName: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  eventInfo: {
    marginBottom: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const SalesPage = () => {
  const classes = useStyles();
  const [timeSlot, setTimeSlot] = useState("10AM");
  const [data, setData] = useState({});
  const [view, setView] = useState("table");
  const [salesDay, setSalesDay] = useState(0); // Default to current day

  const userContext = useEventContext();
  const { urls, TotalUrls, mainUser } = userContext;

  useEffect(() => {
    const fetchData = async () => {
      const now = moment.tz("America/New_York");
      let next350AM = now.clone().hour(3).minute(50).second(0).millisecond(0);

      next350AM.add(salesDay, "day");

      const salesCollection = collection(db, "OnSale");
      const q = query(
        salesCollection,
        where("salesDay", "==", next350AM.toDate()),
        orderBy("date", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const sortedData = sortDataByTime(doc.data().data);
        console.log(doc.data(), doc.data().salesDay.toDate());
        setData(sortedData);
        setTimeSlot(Object.keys(sortedData)[0]);
      });
    };

    fetchData();
  }, [salesDay]);

  const sortDataByTime = (data) => {
    const timeOrder = [
      "5AM",
      "6AM",
      "7AM",
      "8AM",
      "9AM",
      "10AM",
      "11AM",
      "12PM",
      "1PM",
      "2PM",
      "3PM",
      "4PM",
      "5PM",
      "6PM",
      "7PM",
      "8PM",
      "9PM",
      "10PM",
      "11PM",
    ];
    const sortedData = {};
    timeOrder.forEach((time) => {
      if (data[time] && data[time].length > 0) {
        sortedData[time] = data[time].sort((a, b) => {
          if (a.eventName !== b.eventName) {
            return a.eventName.localeCompare(b.eventName);
          }
          return a.date.localeCompare(b.date);
        });
      }
    });
    return sortedData;
  };

  const handleTimeSlotChange = (event, newValue) => {
    setTimeSlot(newValue);
  };

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleSalesDayChange = (event) => {
    setSalesDay(event.target.value);
  };

  const timeSlots = Object.keys(data);

  if (
    auth.currentUser.email !== "test@gmail.com" &&
    auth.currentUser.email !== "dcheck1826@gmail.com"
  ) {
    return null;
  }

  const renderEventCard = (event) => (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" className={classes.eventName}>
          {event.eventName}
        </Typography>
        <Typography className={classes.eventInfo}>
          Venue: {event.venue}
        </Typography>
        <Typography className={classes.eventInfo}>
          Capacity: {event.capacity}
        </Typography>
        <Typography className={classes.eventInfo}>
          Date: {event.date}
        </Typography>
        <Typography className={classes.eventInfo}>
          Offer: {event.offer.map((o) => o).join(", ")}
        </Typography>
        <Typography className={classes.eventInfo}>
          Price Range: {event.priceRange}
        </Typography>
        <Typography className={classes.eventInfo}>
          Sale Date: {event.saleDate}
        </Typography>
        <Typography>
          <a
            href={event.url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Event Link
          </a>
        </Typography>
      </CardContent>
    </Card>
  );

  const renderContent = () => {
    switch (view) {
      case "table":
        return (
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Venue</TableCell>
                  <TableCell>Capacity</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Offer</TableCell>
                  <TableCell>Price Range</TableCell>
                  <TableCell>Sale Date</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Add Event</TableCell>
                  <TableCell>Add Artist</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[timeSlot] &&
                  data[timeSlot].map((event, index) => (
                    <TableRow key={index}>
                      <TableCell>{event.eventName}</TableCell>
                      <TableCell>{event.venue}</TableCell>
                      <TableCell>{event.capacity}</TableCell>
                      <TableCell>{event.date}</TableCell>
                      <TableCell>
                        {event.offer.map((o) => o).join(", ")}
                      </TableCell>
                      <TableCell>{event.priceRange}</TableCell>
                      <TableCell>{event.saleDate}</TableCell>
                      <TableCell>
                        <a // eslint-disable-line jsx-a11y/anchor-is-valid, no-script-url
                          href="#"
                          target="_blank"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(event.url, "_blank");
                          }}
                          rel="noopener noreferrer"
                          className={classes.link}
                        >
                          Link
                        </a>
                      </TableCell>
                      <TableCell>
                        <AddEventButton
                          disabled={urls.includes(event.url)}
                          style={{
                            cursor: urls.includes(event.url)
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={() => {
                            handleAddUrl(
                              "",
                              [event.url],
                              urls,
                              TotalUrls,
                              undefined,
                              mainUser,
                              db
                            );
                          }}
                        >
                          Add Event
                        </AddEventButton>
                      </TableCell>
                      <TableCell>
                        <AddEventButton
                          onClick={() => {
                            if (event.artistUrl[0] === "") {
                              return;
                            }

                            handleAddUrl(
                              "",
                              event.artistUrl,
                              urls,
                              TotalUrls,
                              undefined,
                              mainUser,
                              db
                            );
                          }}
                        >
                          Add Artist
                        </AddEventButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case "grid":
        return (
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            {data[timeSlot] &&
              data[timeSlot].map((event, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  {renderEventCard(event)}
                </Grid>
              ))}
            k
          </Grid>
        );
      case "list":
        return (
          <List style={{ marginTop: "20px" }}>
            {data[timeSlot] &&
              data[timeSlot].map((event, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={event.eventName}
                    secondary={`${event.venue} - ${event.date}`}
                  />
                </ListItem>
              ))}
          </List>
        );
      case "all":
        return (
          <Box style={{ marginTop: "20px" }}>
            {timeSlots.map((slot) => (
              <Box key={slot} mb={4}>
                <Typography variant="h5" className={classes.header}>
                  {slot}
                </Typography>
                <Grid container spacing={3}>
                  {data[slot] &&
                    data[slot].map((event, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        {renderEventCard(event)}
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.header} gutterBottom>
          Sales Data
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Box mt={2} mb={2}>
            <FormControl variant="outlined" className={classes.viewSelect}>
              <InputLabel id="view-select-label">View</InputLabel>
              <Select
                labelId="view-select-label"
                value={view}
                onChange={handleViewChange}
                label="View"
              >
                <MenuItem value="table">Table</MenuItem>
                <MenuItem value="grid">Grid</MenuItem>
                <MenuItem value="list">List</MenuItem>
                <MenuItem value="all">All Times</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <FormControl variant="outlined" className={classes.viewSelect}>
              <InputLabel id="sales-day-select-label">Sales Day</InputLabel>
              <Select
                labelId="sales-day-select-label"
                value={salesDay}
                onChange={handleSalesDayChange}
                label="Sales Day"
              >
                {Array.from({ length: 7 }, (_, i) => (
                  <MenuItem key={i} value={i}>
                    {i === 0 ? "Today" : `In ${i} days`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Tabs
          value={timeSlot}
          onChange={handleTimeSlotChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tabs}
        >
          {timeSlots.map((slot) => (
            <Tab key={slot} label={slot} value={slot} />
          ))}
        </Tabs>

        {renderContent()}
      </Container>
    </ThemeProvider>
  );
};

export default SalesPage;
