import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import { auth, db } from "../../firebase";

import logo from "../../assets/PhantomLogo.png";

import "../../css/LoginPage.css";
import { LoginForm, ResetPasswordForm } from "./forms";
import { handleSession, handleWhopLogin } from "./handler";

const LoginPage = () => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [error, setError] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);

  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem("password", password);
  }, [password]);

  useEffect(() => {
    if (window.location.pathname === "/callback") {
      const code = new URLSearchParams(window.location.search).get("code");

      if (code) {
        fetch(
          `https://us-central1-phantomchecker.cloudfunctions.net/OAuth-whop?code=${code}`
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.token.noUser) {
              setError(
                "No user found with that email address. Please register first."
              );
            } else {
              const token = data.token;
              const userCredential = await signInWithCustomToken(auth, token);
              await handleSession(userCredential, db);
              window.location.href = `${window.location.origin}/`;
            }
          })
          .catch((error) => console.error("Error:", error));
      }
    }
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await handleSession(userCredential, db);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      <div className="logoContainer">
        <img src={logo} alt="Logo" />
      </div>
      {error && <div className="error-message">{error}</div>}
      {!showResetPassword ? (
        <LoginForm
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleSubmit={handleSubmit}
          handleWhopLogin={handleWhopLogin}
          setShowResetPassword={setShowResetPassword}
        />
      ) : (
        <ResetPasswordForm
          email={email}
          setEmail={setEmail}
          setShowResetPassword={setShowResetPassword}
          setPassword={setPassword}
          setError={setError}
        />
      )}
    </div>
  );
};

export default LoginPage;
